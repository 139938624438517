<template>
  <div>

    <div v-if="bankNeedsVerification" class="mb-10">
      <div class="font-bold mb-3">Bank Account Verification</div>

      <div v-if="bankAccountDoesntExist" class="alert alert-red items-start mb-5">
        <span class="alert-icon">
          <ion-icon name="alert-outline" />
        </span>
        <span>
          <span class="block font-bold">This account probably doesn't exist!</span>
          <span class="block font-light">We couldn't even find this account to begin automatic verification.</span>
        </span>
      </div>

      <div class="border border-orange-500 mb-5 overflow-x-auto w-full">
        <table class="table">
          <tbody>
            <tr>
              <th class="text-blue-600">BVN Name</th>
              <td class="text-orange-500 font-bold">{{ bvnName }}</td>
            </tr>
            <tr>
              <th class="text-blue-600">Bank Account Name</th>
              <td class="text-orange-500 font-bold">{{ bankAccountName }}</td>
            </tr>
            <tr>
              <th>Bank</th>
              <td>{{ bankName }}</td>
            </tr>
            <tr>
              <th>Account Number</th>
              <td>{{ accountNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        type="button"
        class="btn btn-red mr-4"
        @click="confirmRefutal"
      >
        Refute
      </button>
      <button
        type="button"
        class="btn btn-green"
        @click="confirmVerification"
      >
        Verify
      </button>
    </div>

    <div class="border border-blue-200 mb-10 overflow-x-auto w-full">
      <table class="table" style="table-layout: fixed">
        <thead>
          <tr>
            <th class="w-1/4">Data Type</th>
            <th class="w-1/4">Attached to BVN</th>
            <th class="w-1/4">Attached to NIN</th>
            <th class="w-1/4">Entered in form</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>First Name</th>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ bvnData.firstName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ ninData.firstName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.firstName }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Middle Name</th>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ bvnData.middleName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ ninData.middleName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('firstName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.middleName }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>
              <span :class="[ isSame('lastName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ bvnData.lastName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('lastName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ ninData.lastName }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('lastName') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.lastName }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Phone Number</th>
            <td>
              <span :class="[ isSame('phoneNumber') ? 'text-green-500' : 'text-orange-500' ]">
                {{ bvnData.phoneNumber }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('phoneNumber') ? 'text-green-500' : 'text-orange-500' ]">
                {{ ninData.phoneNumber }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('phoneNumber') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.phoneNumber }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Phone Number 2</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('phoneNumber2') || '----' }}
              </span>
            </td>
            <td>
              ---
            </td>
            <td>
              -----
            </td>
          </tr>
          <tr>
            <th>Date Of Birth</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ bvnData.dateOfBirth }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ ninData.dateOfBirth }}
              </span>
            </td>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.dateOfBirth }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('gender') || '----' }}
              </span>
            </td>
            <td>
              ---
            </td>
            <td>
            <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ providedData.gender || '----' }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Address</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('residentialAddress') }}
              </span>
            </td>
            <td>
              ---
            </td>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('address') }}
              </span>
            </td>
          </tr>
          <tr>
            <th>State of Residence</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('stateOfResidence') }}
              </span>
            </td>
            <td>
              ---
            </td>
            <td>
              -----
            </td>
          </tr>
          <tr>
            <th>State of Origin</th>
            <td>
              <span :class="[ isSame('dateOfBirth') ? 'text-green-500' : 'text-orange-500' ]">
                {{ getKey('stateOfOrigin') }}
              </span>
            </td>
            <td>
              ---
            </td>
            <td>
              -----
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button
      type="button"
      class="btn btn-blue mb-6"
      @click.prevent="getCreditHistory"
      :disabled="creditHistoryLoading"
    >
      <template v-if="creditHistoryLoading">Fetching...</template>
      <template v-else>Fetch Credit History</template>
    </button>

    <div v-if="creditHistoryLoading">
      <sm-loader />
    </div>

    <template v-else-if="creditHistory">

      <div class="mb-6">
        <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Total Loans</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="globe-outline" class="text-lg mr-2"></ion-icon>
              {{ totalCreditLoans || 0 }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Closed Loans</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="checkmark-done-outline" class="text-lg mr-2"></ion-icon>
              {{ loanCount('closed') || 0 }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Written Off</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
              {{ loanCount('writtenoff') || 0 }}
            </div>
          </div>
          <div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
            <div class="opacity-75 text-sm font-medium mb-1">Open Loans</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="folder-open-outline" class="text-lg mr-2"></ion-icon>
              {{ loanCount('open') || 0 }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">Rating</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="star-outline" class="text-lg mr-2"></ion-icon>
              {{ creditHistory.total_summary ? creditHistory.total_summary.rate : 'N/A' }}
            </div>
          </div>
          <div class="col-span-1 border-t xl:border-t-0 xl:border-l border-blue-200">
            <!-- <div class="opacity-75 text-sm font-medium mb-1">Written Off</div>
            <div class="text-gray-500 text-sm font-light flex flex-row items-center">
              <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
              {{ loanCount('writtenoff') || 0 }}
            </div> -->
          </div>
        </div>

      </div>

      <datatable :data="loanHistory" :columns="creditHistoryColumns" :limit="10" />
    </template>

    <template v-else-if="creditHistoryLoaded">
      <div class="alert alert-orange-soft mb-10">
        <span class="alert-icon">!</span>
        <span class="text-xs font-normal">No Credit History Found.</span>
      </div>
    </template>

    <modal
      ref="refutalModal"
      className="w-full md:w-2/5 xl:w-3/10 p-10"
      @close="refutalModalClosed"
    >
      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Is this information incorrect?
        </div>

        <div class="font-light text-gray-500 text-sm">
          This user's bank information doesn't match his bvn information.
        </div>
      </div>

      <form-group
        v-model="refutalForm.data.comments.value"
        :form="refutalForm"
        name="comments"
        type="textarea"
      >
        Enter Your Comments
      </form-group>

      <template v-if="getFormError(refutalForm)">
        <div class="alert alert-red items-start mb-10">
          <span class="alert-icon">!</span>
          <span class="flex flex-col">
            <span class="font-bold">Unable to refute data!</span>
            <span class="font-light">{{ getFormError(refutalForm) }}</span>
          </span>
        </div>
      </template>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-red"
          @click.prevent="$refs.refutalModal.close"
          :disabled="refutalForm.loading"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-blue ml-3"
          @click.prevent="refute"
          :disabled="refutalForm.loading"
        >
          <sm-loader class="sm-loader-white" v-if="refutalForm.loading" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>

    <modal
      ref="verificationModal"
      className="w-full md:w-2/5 xl:w-3/10 p-10"
      @close="verificationModalClosed"
    >
      <div class="mb-10">
        <div class="text-xl font-bold mb-10">
          Is this information valid?
        </div>

        <div class="font-light text-gray-500 text-sm">
          <p>
            By clicking "Proceed", you signify that you have compared the user's bank information with their BVN information
            and you found them to be a positive match.
          </p>
          <p class="mb-2">
            This action isn't reversible and this user will be given access to loans and credit on the platform.
          </p>
        </div>
      </div>

      <form-group
        v-model="verificationForm.data.comments.value"
        :form="verificationForm"
        name="comments"
        type="textarea"
      >
        Enter Your Comments
      </form-group>

      <template v-if="getFormError(verificationForm)">
        <div class="alert alert-red items-start mb-10">
          <span class="alert-icon">!</span>
          <span class="flex flex-col">
            <span class="font-bold">Unable to verify data!</span>
            <span class="font-light">{{ getFormError(verificationForm) }}</span>
          </span>
        </div>
      </template>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-red"
          @click.prevent="$refs.verificationModal.close"
          :disabled="verificationForm.loading"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-blue ml-3"
          @click.prevent="verify"
          :disabled="verificationForm.loading"
        >
          <sm-loader class="sm-loader-white" v-if="verificationForm.loading" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>

  </div>
</template>
<script>
  export default {
    props: {
      staff: {
        type: Object,
        default: null
      }
    },
    mounted() {
      console.log(this.staff)
    },
    data() {
      return {
        bvnInfo: this.$options.resource({}),
        creditHistory: null,
        creditHistoryColumns: [
          {
            name: 'SubscriberName',
            th: 'Lender'
          },
          {
            name: 'OpeningBalanceAmt',
            th: 'Amount',
            render: (loan, amount) => `₦ ${amount}`
          },
          {
            name: 'InstalmentAmount',
            th: 'Instalment',
            render: (loan, amount) => `₦ ${amount}`
          },
          {
            name: 'LoanDuration',
            th: 'Duration',
            render: (loan, duration) => `${duration} Months`
          },
          {
            name: 'AccountStatus',
            th: 'Status'
          }
        ],
        creditHistoryError: null,
        creditHistoryLoading: false,
        creditHistoryLoaded: false,
        refutalForm: this.$options.basicForm([
          'comments'
        ]),
        verificationForm: this.$options.basicForm([
          'comments'
        ]),
      }
    },
    computed: {
      accountNumber() {
        return this.bankNeedsVerification &&
          this.staff?.profile?.trial_account_no;
      },
      bankAccountName() {
        return this.bankNeedsVerification &&
          this.staff?.profile?.trial_bank_account_name;
      },
      bankName() {
        return this.bankNeedsVerification &&
          this.$options.filters.bankName(
            this.staff?.profile?.trial_bank_code,
            this.$store.state.banks
          );
      },
      bvnData() {
        if (this.staff?.bvn?.data) {
          return {
            ...this.staff.bvn.data,
            phoneNumber: this.staff.bvn.data.phoneNumber1,
            dateOfBirth: this.staff.bvn.data.dateOfBirth,
          };
        }

        return {
          firstName: this.staff?.profile?.bvn_first_name,
          middleName: this.staff?.profile?.bvn_middle_name,
          lastName: this.staff?.profile?.bvn_last_name,
        };
      },
      bvnName() {
        return this.bankNeedsVerification &&
          (
            this.bvnInfo.data?.first_name ?
              `${this.bvnInfo.data?.first_name} ${this.bvnInfo.data?.last_name}`
              : `${this.staff?.profile?.bvn_first_name} ${this.staff?.profile?.bvn_last_name}`
          );
      },
      bankNeedsVerification() {
        return (this.staff?.profile?.trial_account_no && this.staff?.profile?.trial_bank_code);
      },
      bankAccountDoesntExist() {
        return !this.bankAccountName;
      },
      hasStaffRole() {
        return this.staff?.roles?.find(role => role.slug == 'company_staff')
      },
      loanHistory() {
        return this.creditHistory?.account_summary || [];
      },
      ninData() {
        if (this.staff?.nin?.data) {
          return {
            ...this.staff.nin.data,
            dateOfBirth: this.staff.nin.data.birthdate,
            firstName: this.staff.nin.data.firstname,
            middleName: this.staff.nin.data.middlename,
            lastName: this.staff.nin.data.lastname,
            phoneNumber: this.staff.nin.data.phone,
          };
        }

        return {
          dateOfBirth: this.staff?.profile?.nin_date_of_birth,
          firstName: this.staff?.profile?.nin_first_name,
          middleName: this.staff?.profile?.nin_middle_name,
          lastName: this.staff?.profile?.nin_last_name,
          phoneNumber: this.staff?.profile?.nin_phone_no,
        };
      },
      providedData() {
        return {
          ...this.staff?.profile,
          dateOfBirth: this.staff?.profile?.date_of_birth,
          firstName: this.staff?.name,
          middleName: '',
          lastName: this.staff?.last_name,
          phoneNumber: this.staff?.phone_no,
        }
      },
      totalCreditLoans() {
        const writtenoff = this.loanCount('writtenoff');
        const open = this.loanCount('open');
        const closed = this.loanCount('closed');

        return writtenoff + open + closed;
      }
    },
    // beforeMount() {
    //   this.getBvnInfo();
    // },
    methods: {
      compare(provided, propInBvn, propInNin) {
        const s = (a) => typeof a === 'string' ? a.toLowerCase() : a;
        return s(provided) === s(propInBvn) && s(provided) === s(propInNin);
      },
      loanCount(key) {
        const loanCounts = this.creditHistory?.total_summary?.loan_count;
        return loanCounts && loanCounts[key] ? loanCounts[key] : 0;
      },
      isSame(key) {
        return this.compare(this.providedData[key], this.bvnData[key], this.ninData[key]);
      },
      confirmRefutal() {
        this.$refs.refutalModal.open();
      },
      confirmVerification() {
        this.$refs.verificationModal.open();
      },
      refutalModalClosed() {
        if (this.refutalForm.loading) {
          this.confirmRefutal();
        }
      },
      getKey(key) {
        return this.bvnData[key] ?? this.providedData[key] ?? '-----'
      },
      verificationModalClosed() {
        if (this.verificationForm.loading) {
          this.confirmVerification();
        }
      },
      async refute() {
        if (!this.validateForm(this.refutalForm)) {
          return false;
        }

        this.refutalForm.error = false;
        this.refutalForm.loading = true;

        await this.sendRequest('admin.users.bankAccount.refute', this.staff?.id, {
          data: this.getFormData(this.refutalForm),
          success: () => {
            this.$emit('success')
          },
          error: error => {
            this.refutalForm.error = error;
          }
        });

        this.refutalForm.loading = false;
      },
      async verify() {
        if (!this.validateForm(this.verificationForm)) {
          return false;
        }

        this.verificationForm.error = false;
        this.verificationForm.loading = true;

        await this.sendRequest('admin.users.bankAccount.verify', this.staff?.id, {
          data: this.getFormData(this.verificationForm),
          success: () => {
            this.$emit('success')
          },
          error: error => {
            this.verificationForm.error = error;
          }
        });

        this.verificationForm.loading = false;
      },
      async getCreditHistory() {
        this.creditHistoryLoading = true;
        await this.$get({
          url: `${this.$baseurl}/staff/credit/history/${this.staff.id}`,
          headers: this.headers,
          success: (response) => {
            this.creditHistory = response.data.history;
          }
        });
        this.creditHistoryLoading = false;
        this.creditHistoryLoaded = true;
      },
      async getBvnInfo() {
        this.bvnInfo.loading = true;
        await this.$get({
          url: `https://credpal.com/api/resolve_bvn/${this.staff?.profile?.bvn}`,
          headers: {
            Authorization: `Bearer CP_ADVANCLY_WSDRT7FN2IUTVDLO9U2`
          },
          success: (response) => {
            this.bvnInfo.data = response.data.data;
          }
        });
        this.bvnInfo.loading = false;
      },
    }
  }
</script>
